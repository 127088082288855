// boilerplate generated 2024-05-19 08:47:07
import Vue from 'vue';

import Vuex from 'vuex';
import location from '@/vuex/location.js';
import session from '@/vuex/session.js';
import navigation from '@/vuex/navigation.js';
import context from '@/vuex/context.js';
import checkpoints from '@/vuex/checkpoints.js';
import controlpanels from '@/vuex/controlpanels.js';
import indicators from '@/vuex/indicators.js';
import database from '@/vuex/database.js';
import databases from '@/vuex/databases.js';
import sensors from '@/vuex/sensors.js';
import datapoints from '@/vuex/datapoints.js';
import actions from '@/vuex/actions.js';
import systemd from '@/vuex/systemd.js';
import timelines from '@/vuex/timelines.js';
import timers from '@/vuex/timers.js';
import server from '@/vuex/server.js';
import socket from '@/vuex/socket.js';

Vue.use(Vuex)
export default new Vuex.Store({
strict: true,
  modules: {
	location,
	session,
	navigation,
	context,
	checkpoints,
	controlpanels,
	indicators,
	database,
	databases,
	sensors,
	datapoints,
	actions,
	systemd,
	timelines,
	timers,
	server,
	socket,
	}
})
